export const useScrollMain = () => {
  // state
  const scrollEl = ref<HTMLDivElement | null>(null);
  onMounted(() => {
    const htmlTag = document.querySelector<HTMLDivElement>("div.scroll-element");
    scrollEl.value = htmlTag;
  });
  onUnmounted(() => {
    scrollEl.value = null;
  });
  return { scrollEl };
};
